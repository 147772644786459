class Slider {
    constructor() {
        const swiper = new Swiper('.swiper', {
            centeredSlides: true,
            loop: true,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
        });
    };
}

export {Slider}
