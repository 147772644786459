class BtnShare {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    };

    let initCache = () => {
      cache.btnShare = document.getElementById("blog-share");
      cache.defaultMessage = document.getElementById("default-message");
      cache.successMessage = document.getElementById("success-message");
    };

    let registerEvents = () => {
      if (cache.btnShare) {
        cache.btnShare.addEventListener("click", copyUrl);
      }
    };

    let copyUrl = () => {
      const pageUrl = window.location.href;
      navigator.clipboard
        .writeText(pageUrl)
        .then(function() {
          // Change l'état du bouton en "Copied"
          cache.defaultMessage.classList.add("hidden");
          cache.successMessage.classList.remove("hidden");

          // Après un délai (ex. : 2 secondes), revenir à l'état par défaut
          setTimeout(function() {
            cache.defaultMessage.classList.remove("hidden");
            cache.successMessage.classList.add("hidden");
          }, 2000); // 2 secondes de délai
        })
        .catch(function(error) {
          console.error("Erreur lors de la copie de l'URL :", error);
        });
    };

    initFunc();
  }
}
export { BtnShare };
