import anime from "../libs/anime";
import globalVar from "../utils/globalVar";

class SidebarTabs {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    };

    let initCache = () => {
      cache.tabLinks = document.querySelectorAll(".title-anchor");
      cache.tabs = document.querySelectorAll(".tabs-item");
    };

    let registerEvents = () => {
      // Par défaut, on affiche le premier tab et active le premier lien
      cache.tabs[0].classList.add("active");
      cache.tabLinks[0].classList.add("active");

      // Ajouter un événement de clic à chaque lien du menu
      cache.tabLinks.forEach((link) => {
        link.addEventListener("click", (e) => {
          e.preventDefault(); // Empêche le scroll natif

          const targetTitle = link.getAttribute("data-title");

          // Masquer tous les onglets et désactiver les liens actifs
          cache.tabs.forEach((tab) => {
            if (tab.querySelector(`h2[data-title="${targetTitle}"]`)) {
              tab.classList.add("active");
            } else {
              tab.classList.remove("active");
            }
          });

          cache.tabLinks.forEach((tabLink) => {
            tabLink.classList.remove("active");
          });

          // Activer l'onglet cliqué et son lien correspondant
          link.classList.add("active");
        });
      });
    };

    initFunc();
  }
}
export { SidebarTabs };
