class ColorsVariations {
    constructor() {
      let cache = {};
      let initFunc = () => {
        initCache();
        registerEvents();
      };
  
      let initCache = () => {
        cache.colorButtons = document.querySelectorAll(".color-button");
      };
  
      let registerEvents = () => {
        cache.colorButtons.forEach((button) => {
          button.addEventListener("click", (event) => {
            this.selectItem(button);
          });
        });
      };
  
      this.selectItem = (button) => {
        const attributeName = button.getAttribute("data-attribute_name");
        const value = button.getAttribute("data-value");
  
        const select = document.querySelector(
          `select[name="attribute_${attributeName}"]`
        );
  
        if (select) {
          select.value = value; 
          const changeEvent = new Event("change");
          select.dispatchEvent(changeEvent);
  
          document.querySelectorAll(".color-button").forEach((btn) => {
            btn.classList.remove("active");
          });
          button.classList.add("active");
        }
      };
  
      initFunc();
    }
  }
  export { ColorsVariations };