import anime from "../libs/anime";
import globalVar from "../utils/globalVar";

class Header {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    };

    let initCache = () => {
      cache.header = document.querySelector("header.site-header");
      cache.lastScrollTop = 0;
    };

    let registerEvents = () => {
      window.addEventListener("scroll", scrollingWheel);
    };

    let scrollingWheel = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > cache.lastScrollTop) {
        cache.header.style.transform = "translateY(-150%)";
      } else {
        cache.header.style.transform = "translateY(0)";
      }

      cache.lastScrollTop = scrollTop;
    };

    initFunc();
  }
}
export { Header };
