// import anime from '../../libs/anime'
// import globalVar from '../globalVar'

// function scrollTo(e, trigger) {
//   document.querySelector('body').classList.add('scroll');
//   e.preventDefault();
//   var target = document.querySelector(trigger.getAttribute("href"));
//   anime({
//     targets: globalVar.scrollElement,
//     scrollTop: (globalVar.scrollElement.scrollTop+target.getBoundingClientRect().top),
//     duration: 800,
//     easing: 'easeOutQuad',
//     complete: function () {
//       document.querySelector('body').classList.remove('scroll');
//     }
//   });
// }
// module.exports = { scrollTo }
