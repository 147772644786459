class Toggle {
  constructor(selector, toggleClass = "toggle", activeClass = "active") {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    };

    let initCache = () => {
      cache.toggleButtons = document.querySelectorAll(
        `${selector} button, ${selector} .question`
      );
    };

    let registerEvents = () => {
      cache.toggleButtons.forEach((button) => {
        button.addEventListener("click", function() {
          toggleBtn(this);
        });
      });
    };

    let toggleBtn = (button) => {
      const toggleDiv = button.nextElementSibling;
      const isOpen = button.classList.contains("open");

      document
        .querySelectorAll(`${selector} .${toggleClass}`)
        .forEach((div) => {
          if (div !== toggleDiv) {
            div.classList.remove("show");
          }
        });
      document
        .querySelectorAll(`${selector} button, ${selector} .question`)
        .forEach((btn) => {
          if (btn !== button) {
            btn.classList.remove("open");
          }
        });

      if (isOpen) {
        button.classList.remove("open");
        toggleDiv.classList.remove("show");
      } else {
        button.classList.add("open");
        toggleDiv.classList.add("show");
      }
    };

    initFunc();
  }
}
export { Toggle };
