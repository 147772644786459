class SliderHomepage {
  constructor() {
    const swiper = new Swiper(".slider-homepage", {
      centeredSlides: true,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }
}

export { SliderHomepage };
