import anime from "../libs/anime";
import globalVar from "../utils/globalVar";

class Nav {
  constructor() {
    let prevTab;
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    };

    let initCache = () => {
      cache.nav = document.querySelector(".nav-mobile");
      cache.navTriggerDesk = document.querySelectorAll(
        "header .wrapper-nav .top-title"
      );
      cache.navTriggerDeskSearch = document.querySelector(
        "header .wrapper-nav .btn-search"
      );
      cache.navCloseDesk = document.querySelector(
        "header .nav_desktop .btn_close"
      );
      cache.navDesk = document.querySelector("header .nav_desktop");
      cache.navFilter = document.querySelector("header .filter");
      cache.navmobileBtn = document.querySelector(".nav_mobile");
      cache.header = document.querySelector(".site-header");

      cache.categoryItems = document.querySelectorAll(".category-item");
      cache.contentItems = document.querySelectorAll(".content-item");
    };

    let registerEvents = () => {
      for (let i = 0; i < cache.navTriggerDesk.length; i++) {
        cache.navTriggerDesk[i].addEventListener("click", openNav);
      }

      cache.navTriggerDeskSearch.addEventListener("click", openNav);

      // Gestion des clics sur les catégories du nav_desktop
      for (let i = 0; i < cache.categoryItems.length; i++) {
        cache.categoryItems[i].addEventListener("click", handleCategoryClick);
      }

      cache.navmobileBtn.addEventListener("click", toggleNav);
    };

    // DESKTOP
    let openNav = function() {
      for (let i = 0; i < cache.navTriggerDesk.length; i++) {
        cache.navTriggerDesk[i].removeEventListener("click", openNav);
        cache.navTriggerDesk[i].addEventListener("click", changeTabNav);
      }

      cache.navTriggerDeskSearch.removeEventListener("click", openNav);

      if (this.classList.contains("btn_menu")) {
        this.addEventListener("click", changeTabNavBwOut);
        cache.navTriggerDeskSearch.addEventListener("click", changeTabNav);
      } else if (this.classList.contains("search_btn")) {
        cache.navTriggerDeskSearch.addEventListener(
          "click",
          changeTabNavSearchOut
        );
        if (cache.navmobileBtn.classList.contains("active")) {
          closeNavMobile();
        }
      } else {
        cache.navTriggerDeskSearch.addEventListener("click", changeTabNav);
        this.addEventListener("click", changeTabNav);
      }

      prevTab = this;
      this.classList.add("active");
      this.removeEventListener("click", changeTabNav);
      let activeTab = cache.navDesk.querySelector("." + this.dataset.tab);

      cache.navDesk.style.display = "block";
      cache.navFilter.style.display = "block";
      activeTab.style.display = "flex";
      activeTab.style.opacity = 1;

      cache.navDesk.style.height = "auto";
      let height = cache.navDesk.offsetHeight;
      cache.navDesk.style.height = "0";

      if (this.classList.contains("active")) {
        this.removeEventListener("click", changeTabNav);
        this.addEventListener("click", closeNav);
      }

      document.querySelector("body").classList.add("overflow");
      document.querySelector("html").classList.add("overflow");

      anime({
        targets: cache.navDesk,
        height: height,
        duration: 400,
        easing: "easeOutQuad",
        complete: function() {
          cache.navCloseDesk.addEventListener("click", closeNav);
          cache.navFilter.addEventListener("click", closeNav);

          if (cache.categoryItems.length > 0) {
            cache.categoryItems[0].classList.add("active");
            let firstContent = cache.contentItems[0];
            firstContent.style.display = "flex";
            anime({
              targets: firstContent,
              opacity: [0, 1],
              duration: 300,
              easing: "easeOutQuad",
            });
          }
        },
      });

      anime({
        targets: cache.navFilter,
        opacity: [0, 1],
        duration: 400,
        easing: "easeOutQuad",
      });
    };

    let closeNav = function() {
      cache.navTriggerDesk.forEach((trigger) => {
        trigger.classList.remove("active");
      });
      cache.navTriggerDeskSearch.classList.remove("active");

      let activeTab = cache.navDesk.querySelector("div[data-tab].active");
      if (activeTab) {
        anime({
          targets: activeTab,
          opacity: [1, 0],
          duration: 300,
          easing: "easeOutQuad",
          complete: function() {
            activeTab.style.display = "none";
            activeTab.classList.remove("active");
          },
        });
      }

      cache.navCloseDesk.removeEventListener("click", closeNav);
      cache.navFilter.removeEventListener("click", closeNav);

      cache.navTriggerDeskSearch.removeEventListener("click", changeTabNav);
      cache.navTriggerDeskSearch.removeEventListener(
        "click",
        changeTabNavSearchOut
      );

      document.querySelector("body").classList.remove("overflow");
      document.querySelector("html").classList.remove("overflow");

      anime({
        targets: cache.navDesk,
        height: 0,
        duration: 400,
        easing: "easeOutQuad",
        complete: function() {
          cache.navDesk.style.display = "none";
          cache.navTriggerDesk.forEach((trigger) => {
            trigger.addEventListener("click", openNav);
          });
          cache.navTriggerDeskSearch.addEventListener("click", openNav);
        },
      });

      anime({
        targets: cache.navFilter,
        opacity: [1, 0],
        duration: 400,
        easing: "easeOutQuad",
        complete: function() {
          cache.navFilter.style.display = "none";
        },
      });

      prevTab = null;
    };

    let changeTabNav = function() {
      let activeTitle = document.querySelector(
        "header .wrapper-nav .top-title.active"
      );

      if (!activeTitle) {
        if (cache.navTriggerDeskSearch.classList.contains("active")) {
          activeTitle = cache.navTriggerDeskSearch;
          activeTitle.addEventListener("click", changeTabNav);
          activeTitle.removeEventListener("click", changeTabNavSearchOut);
        }
      } else {
        activeTitle.removeEventListener("click", closeNav);
        activeTitle.addEventListener("click", changeTabNav);
      }
      activeTitle.classList.remove("active");
      let activeTab = cache.navDesk.querySelector(
        "." + activeTitle.dataset.tab
      );
      let targetTitle = this;
      targetTitle.classList.add("active");
      if (targetTitle.classList.contains("search_btn")) {
        targetTitle.removeEventListener("click", changeTabNav);
        targetTitle.addEventListener("click", changeTabNavSearchOut);
      } else {
        targetTitle.removeEventListener("click", changeTabNav);
        prevTab = targetTitle;
      }
      let targetTab = cache.navDesk.querySelector(
        "." + targetTitle.dataset.tab
      );

      this.addEventListener("click", () => {
        if (this.classList.contains("active")) {
          targetTitle.removeEventListener("click", changeTabNav);
          targetTitle.addEventListener("click", openNav);
          closeNav();
        }
      });

      anime({
        targets: activeTab,
        opacity: [1, 0],
        duration: 300,
        easing: "easeOutQuad",
        complete: function() {
          activeTab.style.display = "none";
        },
      });

      targetTab.style.display = "flex";
      anime({
        targets: targetTab,
        opacity: [0, 1],
        duration: 300,
        easing: "easeOutQuad",
      });
    };

    let changeTabNavSearchOut = function() {
      if (prevTab && !prevTab.classList.contains("search_btn")) {
        let activeTitle = cache.navTriggerDeskSearch;
        activeTitle.removeEventListener("click", changeTabNavSearchOut);
        activeTitle.addEventListener("click", changeTabNav);
        activeTitle.classList.remove("active");
        let activeTab = cache.navDesk.querySelector(
          "." + activeTitle.dataset.tab
        );

        let targetTitle = prevTab;
        targetTitle.classList.add("active");
        targetTitle.removeEventListener("click", changeTabNav);
        let targetTab = cache.navDesk.querySelector(
          "." + targetTitle.dataset.tab
        );

        anime({
          targets: activeTab,
          opacity: [1, 0],
          duration: 300,
          easing: "easeOutQuad",
          complete: function() {
            activeTab.style.display = "none";
          },
        });

        targetTab.style.display = "flex";
        anime({
          targets: targetTab,
          opacity: [0, 1],
          duration: 300,
          easing: "easeOutQuad",
        });
      } else {
        closeNav();
      }
    };

    // Gestion du clic sur les catégories sur le nav_desktop
    let handleCategoryClick = function() {
      let categoryIndex = this.getAttribute("data-category-index");

      for (let i = 0; i < cache.contentItems.length; i++) {
        cache.contentItems[i].style.display = "none";
        anime({
          targets: cache.contentItems[i],
          opacity: [1, 0],
          duration: 300,
          easing: "easeOutQuad",
        });
      }

      let contentToShow = cache.navDesk.querySelector(
        '.content-item[data-category-index="' + categoryIndex + '"]'
      );

      contentToShow.style.display = "flex";
      anime({
        targets: contentToShow,
        opacity: [0, 1],
        duration: 300,
        easing: "easeOutQuad",
      });
    };

    let toggleNav = () => {
      if (cache.nav.classList.contains("active")) {
        closeNavMobile();
      } else {
        openNavMobile();
      }
    };

    // MOBILE
    let openNavMobile = () => {
      if (cache.navTriggerDeskSearch.classList.contains("active")) {
        closeNav();
      }
      cache.navmobileBtn.removeEventListener("click", openNav);
      cache.nav.style.display = "flex";
      document.querySelector("body").classList.add("overflow");
      document.querySelector("html").classList.add("overflow");
      anime({
        targets: cache.nav,
        opacity: [0, 1],
        translateX: [250, 0],
        duration: 400,
        easing: "easeOutQuad",
        complete: function() {
          cache.navmobileBtn.addEventListener("click", closeNav);
        },
      });
    };

    let closeNavMobile = () => {
      cache.navmobileBtn.removeEventListener("click", closeNav);
      document.querySelector("body").classList.remove("overflow");
      document.querySelector("html").classList.remove("overflow");
      anime({
        targets: cache.nav,
        opacity: [1, 0],
        translateX: [0, 250],
        duration: 400,
        easing: "easeOutQuad",
        complete: function() {
          cache.navmobileBtn.addEventListener("click", openNav);
          cache.nav.style.display = "none";
        },
      });
    };

    initFunc();
  }
}

export { Nav };
