class MiniCart {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    };

    let initCache = () => {
      cache.cartButton = document.getElementById("cart-button");
      cache.miniCart = document.getElementById("mini-cart");
      cache.closeCartButton = document.getElementById("close-mini-cart");
    };

    let registerEvents = () => {
      cache.cartButton.addEventListener("click", function() {
        cache.miniCart.classList.toggle("hidden");
        loadMiniCart();
      });

      cache.closeCartButton.addEventListener("click", function() {
        cache.miniCart.classList.add("hidden");
      });

      document.addEventListener("click", function(event) {
        if (event.target.closest(".increment")) {
          const input = event.target.closest(".custom-quantity").querySelector("input.qty");
          input.stepUp();
          input.dispatchEvent(new Event("change"));
        } else if (event.target.closest(".decrement")) {
          const input = event.target.closest(".custom-quantity").querySelector("input.qty");
          input.stepDown();
          input.dispatchEvent(new Event("change"));
        }
      });

      document.addEventListener("change", function(event) {
        if (event.target.closest(".mini-cart-quantity input.qty")) {
          updateQuantityInCart(event.target);
        }
      });
    };

    let loadMiniCart = () => {
      jQuery.ajax({
        url: wc_cart_params.ajax_url,
        type: "POST",
        data: {
          action: "get_mini_cart_content",
        },
        success: function(response) {
          document.querySelector(".mini-cart-items").innerHTML = response;
        },
        error: function(error) {
          console.error("Erreur lors du chargement du panier", error);
        },
      });
    };

    let updateQuantityInCart = (input) => {
      const quantity = input.value;
      const cartKey = input.name.match(/\[(.*?)\]/)[1]; 

      // Envoyer la requête AJAX pour mettre à jour la quantité
      jQuery.ajax({
        url: wc_cart_params.ajax_url,
        type: "POST",
        data: {
          action: "update_cart_item",
          cart_key: cartKey,
          quantity: quantity,
        },
        success: function(response) {
          loadMiniCart(); 
        },
        error: function(error) {
          console.error("Erreur lors de la mise à jour du panier", error);
        },
      });
    };

    initFunc();
  }
}

export { MiniCart };