class DropdownFilters {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    };

    let initCache = () => {
      cache.toggles = document.querySelectorAll(".dropdown-toggle");
    };

    let registerEvents = () => {
      cache.toggles.forEach((toggle) => {
        toggle.addEventListener("click", (event) => toggleDropdown(event));
      });
    };

    let toggleDropdown = (event) => {
      const toggle = event.currentTarget;
      const parentGroup = toggle.closest(".filter-group");
      toggle.classList.toggle("active");

      const nextElement = toggle.nextElementSibling;
      if (nextElement && nextElement.classList.contains("filter-options")) {
        if (nextElement.classList.contains("active")) {
          nextElement.style.maxHeight = null;
          nextElement.classList.remove("active");
          parentGroup.classList.remove("open");
        } else {
          nextElement.style.maxHeight = (nextElement.scrollHeight + 14) + "px";
          nextElement.classList.add("active");
          parentGroup.classList.add("open");
        }
      }
    };

    initFunc();
  }
}
export { DropdownFilters };
