import { Header } from "./components/header";
import { Nav } from "./components/nav";
import { Slider } from "./components/slider";
import { SliderHomepage } from "./components/sliderHomepage";
import { SliderBlog } from "./components/sliderBlog";
import { SliderGallery } from "./components/sliderGallery";
import { Toggle } from "./components/toggle";
import { RollingNumber } from "./modules/module-05-rolling-number";
import { SidebarTabs } from "./modules/module-16-sidebar-tabs";
import { ToggleKeyFeatures } from "./modules/module-19-key-features";
import { BtnReadMore } from "./components/btnReadMore";
import { BtnShare } from "./components/btnShare";
import { ColorsVariations } from "./woocommerce/pages/single-product/colorsVariations";
import { DropdownFilters } from "./woocommerce/components/dropdownFilters";

// import { PopUp } from './components/popup'
// import { DropDown } from './components/dropdown'

class App {
  constructor() {
    const header = new Header();
    const nav = new Nav();

    if (document.querySelector(".slider-blog-posts")) {
      const sliderBlog = new SliderBlog();
    }

    if (document.querySelector(".slider-homepage")) {
      const sliderHomepage = new SliderHomepage();
    }

    if (document.querySelector(".slider-gallery")) {
      const sliderGallery = new SliderGallery();
    }

    if (
      document.querySelector(".swiper") &&
      !document.querySelector(".slider-homepage") &&
      !document.querySelector(".slider-blog-posts") &&
      !document.querySelector(".slider-gallery")
    ) {
      const slider = new Slider();
    }

    if (document.querySelector(".key-tabs")) {
      const toggleKeyTabs = new ToggleKeyFeatures(".key-tabs");
    }

    if (document.querySelector(".faqs")) {
      const toggleFaqs = new Toggle(".faqs", "answer");
    }

    if (document.querySelector(".module-05-rolling-number")) {
      const rollingNumber = new RollingNumber();
    }

    if (document.querySelector(".module-16-sidebar-tab")) {
      const sidebarTabs = new SidebarTabs();
    }

    if (document.querySelector(".module-19-key-features")) {
      const toggleKeyFeatures = new ToggleKeyFeatures();
    }

    if (document.querySelector(".module-20-tech-specs")) {
      const btnReadMore = new BtnReadMore();
    }

    if (document.querySelector(".btn-share")) {
      const btnShare = new BtnShare();
    }

    // ==== WooCommerce ===== //

    if (document.querySelector(".template-cpt-product")) {
      const colorsVariations = new ColorsVariations();
    }

    if (document.querySelector(".filter-group")) {
      const dropdownFilters = new DropdownFilters();
    }

    // if(document.querySelector('.dropdown')) {
    //   const dropdown = new DropDown;
    // }
    // if(document.querySelector('.popup')) {
    //   const popup = new PopUp;
    // }
  }
}

window.onload = function() {
  let app = new App();
};
