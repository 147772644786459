import utils from '../utils/utils'
import anime from '../libs/anime'
import globalVar from '../utils/globalVar'
import scrollTo from '../utils/helpers/scrollTo'

class Nav {
  constructor() {
    let cache = {};
    let initFunc = () => {
      // initCache();
      // registerEvents();
    }

    let initCache = () => {
      cache.nav = document.querySelector('.nav');
      cache.navmobileBtn = document.querySelector('.nav_mobile');
    };

    let registerEvents = () => {
      cache.navmobileBtn.addEventListener("click", openNav);
    };

    let openNav = () => {
      cache.navmobileBtn.removeEventListener("click", openNav);
      cache.navmobile.style.display="flex";
      document.querySelector('body').classList.add('overflow');
      document.querySelector('html').classList.add('overflow');
      anime({
        targets: cache.navmobileBtn.querySelector('.burger'),
        opacity: [1,0],
        duration: 400,
        easing: 'easeOutQuad'
      });
      anime({
        targets: cache.navmobileBtn.querySelector('.close'),
        opacity: [0,1],
        duration: 400,
        easing: 'easeOutQuad'
      });
      anime({
        targets: cache.navmobile,
        opacity: [0,1],
        translateX: [250,0],
        duration: 400,
        easing: 'easeOutQuad',
        complete: function() {
          cache.navmobileBtn.addEventListener("click", closeNav);
        }
      });
    }

    let closeNav = () => {
      cache.navmobileBtn.removeEventListener("click", closeNav);
      document.querySelector('body').classList.remove('overflow');
      document.querySelector('html').classList.remove('overflow');
      anime({
        targets: cache.navmobileBtn.querySelector('.burger'),
        opacity: [0,1],
        duration: 400,
        easing: 'easeOutQuad'
      });
      anime({
        targets: cache.navmobileBtn.querySelector('.close'),
        opacity: [1,0],
        duration: 400,
        easing: 'easeOutQuad'
      });
      anime({
        targets: cache.navmobile,
        opacity: [1,0],
        translateX: [0,250],
        duration: 400,
        easing: 'easeOutQuad',
        complete: function() {
          cache.navmobileBtn.addEventListener("click", openNav);
          cache.navmobile.style.display="none";
        }
      });
    }

    initFunc();
  };
}
export { Nav }
