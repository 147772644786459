class ToggleKeyFeatures {
  constructor(selector, toggleClass = "toggle-answer", activeClass = "active") {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    };

    let initCache = () => {
      cache.toggleButtons = document.querySelectorAll(
        `${selector} .btn-toggle`
      );
    };

    let registerEvents = () => {
      cache.toggleButtons.forEach((button) => {
        button.addEventListener("click", () => {
          toggleBtn(button);
        });
      });
    };

    let toggleBtn = (button) => {
      const toggleDiv = button
        .closest(".key-tabs")
        .querySelector(`.${toggleClass}`);
      const isOpen = button.classList.contains("open");

      // Fermer tous les autres toggles sauf celui cliqué
      document
        .querySelectorAll(`${selector} .${toggleClass}`)
        .forEach((div) => {
          if (div !== toggleDiv) {
            div.classList.remove("show");
          }
        });

      document.querySelectorAll(`${selector} .btn-toggle`).forEach((btn) => {
        if (btn !== button) {
          btn.classList.remove("open");
        }
      });

      if (isOpen) {
        button.classList.remove("open");
        toggleDiv.classList.remove("show");
      } else {
        button.classList.add("open");
        toggleDiv.classList.add("show");
      }
    };

    initFunc();
  }
}

export { ToggleKeyFeatures };