class SliderGallery {
  constructor() {
    const swiper = new Swiper(".slider-gallery", {
      slidesPerView: 2,
      centeredSlides: true,
      spaceBetween: 25,
      loop: true,
      grabCursor: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  }
}

export { SliderGallery };
