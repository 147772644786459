class BtnReadMore {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    };

    let initCache = () => {
      cache.btnReadMore = document.querySelector(".btn-read-more");
      cache.specsWrapper = document.querySelector(".wrapper-specs");

    };

    let registerEvents = () => {
      if (cache.btnReadMore) {
        cache.btnReadMore.addEventListener("click", openReadMore);
      }
    };

    let openReadMore = () => {
      if (cache.specsWrapper) {
        cache.specsWrapper.classList.toggle("show");
    }
    cache.btnReadMore.classList.toggle("show");
    };

    initFunc();
  }
}
export { BtnReadMore };
