import anime from "../libs/anime";
import globalVar from "../utils/globalVar";

class RollingNumber {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    };

    let initCache = () => {
      cache.numberElement = document.getElementById("rolling-number");
    };

    let registerEvents = () => {
      separateChar();
    };

    let separateChar = () => {
      if (cache.numberElement) {
        const numberText = cache.numberElement.textContent;

        // Sépare chaque chiffre avec un <span>
        const spannedNumber = numberText
          .split("")
          .map(function(char) {
            return `<span>${char}</span>`;
          })
          .join("");

        cache.numberElement.innerHTML = spannedNumber;
      }
    };

    initFunc();
  }
}
export { RollingNumber };
